<template>
  <div id="category-menu" data-cy-region="category-menu" class="hidden md:block">
    <div>
      <ul
        v-if="levelOneCategories && levelOneCategories.length"
        data-attr="header__megaMenu__list"
        class="CategoryList py-4 [ flex items-center gap-x-6 justify-center ] capitalize text-base"
      >
        <li class="relative flex items-center" @mouseover="selectedItem = undefined">
          <AppLink to="/new-in" class="NewIn font-thin hover:font-bold flex items-center justify-between">
            <svg-icon-new width="24" height="24" class="w-6 h-6 mr-2" />
            <div class="relative max-h-6">
              <span class="my-auto absolute text-system-green top-0 left-0">{{ $t('newIn') }} </span>
              <span class="my-auto font-bold invisible">{{ $t('newIn') }} </span>
            </div>
          </AppLink>
        </li>

        <li
          v-for="(category, index) in levelOneCategories?.slice(0, 6)"
          :key="index"
          :data-attr="`megaMenu__categories__${category.url_key}`"
          class="relative"
          @mouseover="
            selectedItem = {
              type: 'category',
              payload: category,
            }
          "
        >
          <AppLink :to="`/${category.url_key}`" class="flex items-center justify-between">
            <span class="my-auto absolute top-0 left-0">
              {{ category.name?.toLowerCase() }}
            </span>

            <span class="my-auto font-bold invisible">
              {{ category.name?.toLowerCase() }}
            </span>
          </AppLink>
        </li>

        <li @mouseover="selectedItem = undefined">
          <AppLink to="/deals" class="DealsLink flex items-center justify-between h-full text-primary-2-100">
            <div class="relative max-h-6">
              <span class="mr-2 absolute top-0 left-0">{{ $t('sale') }} </span>
              <span class="mr-2 font-bold invisible">{{ $t('sale') }} </span>
            </div>
            <svg-icon-sale width="24" height="24" class="w-6 h-6 text-primary-2-100" />
          </AppLink>
        </li>
      </ul>

      <transition name="fade">
        <div v-if="selectedItem" class="absolute left-0 right-0 bg-white w-full z-10 border-t border-primary-1-10">
          <div v-if="selectedItem.payload?.children?.length" class="container mx-auto CategoryMenu">
            <div class="CategoryMenu__categories">
              <div v-for="child in selectedItem.payload?.children" :key="child.id || ''" class="SubCategory">
                <h3 class="antialiased tracking-wide text-base capitalize">
                  <AppLink :to="`/${child.url_key}`">{{ child.name }}</AppLink>
                </h3>
              </div>

              <AppLink
                :to="`/${selectedItem.payload?.url_key}`"
                class="mt-8 col-span-4 underline text-base capitalize font-semibold [ flex items-center space-x-5 ]"
              >
                {{ $t('shopAll', { name: selectedItem.payload?.name }).toLowerCase() }}
              </AppLink>
            </div>
            <div v-if="offer" class="w-114">
              <OfferCard class="h-64" v-bind="offer">
                <template #title>
                  <p class="text-black font-semibold font-body text-base">
                    {{ offer.title }}
                  </p>
                </template>
              </OfferCard>
            </div>
          </div>

          <Teleport to="#after-header">
            <div class="bg-black fixed inset-0 w-full h-full opacity-50 z-20"></div>
            <!-- @mouseover="selectedCategory = null" -->
          </Teleport>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Category } from '@robustastudio/e-commerce/common';
import { debounce } from 'lodash-es';

const { t: $t } = useI18n({
  useScope: 'local',
});

let listener: ReturnType<typeof document.addEventListener> | undefined;

const route = useRoute();

const data = await useMegaMenuCategories();

// display only level one categories in mega menu
const levelOneCategories = computed(() => {
  return data?.categories
    ?.filter(category => category.level === 1)
    .sort((a, b) => (a?.order || Number.MAX_VALUE) - (b.order || Number.MAX_VALUE));
});

const selectedItem = ref<{ type: 'brand' | 'category'; payload: Category | undefined } | undefined>(undefined);

watch(selectedItem, value => {
  if (!value && listener) {
    document.removeEventListener('mousemove', listener);
    return;
  }
  if (value)
    listener = document.addEventListener(
      'mouseover',
      debounce(e => {
        if (e.target instanceof Element && document.getElementById('category-menu')?.contains(e.target)) return;
        selectedItem.value = undefined;
      }, 200),
    );
});

watch(
  () => route.path,
  () => {
    selectedItem.value = undefined;
  },
);

const offer = computed(() => {
  return (selectedItem.value as any)?.offer;
});
</script>

<style lang="postcss" scoped>
.CategoryList {
  .nuxt-link-active,
  .router-link-active,
  a {
    position: relative;
  }
  .nuxt-link-active,
  .router-link-active,
  a:hover {
    @apply font-semibold;

    &:not(.DealsLink):not(.NewIn)::after {
      @apply bg-primary-1-100;
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0px;
      width: 28px;
      height: 2px;
    }
  }
}

.CategoryMenu {
  display: grid;
  grid-template-columns: 1fr fit-content(456px);
  column-gap: 45px;
  padding-top: 40px;
  padding-bottom: 40px;

  max-height: 90vh;
  min-height: 300px;

  .CategoryMenu__categories {
    display: grid;
    grid-template-columns: 260px 1fr 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;
  }
}

.SubCategory {
  & > ul {
    @apply flex flex-col flex-wrap  gap-y-6 max-h-80;
  }
}
</style>

<i18n>
{
  "en": {
    "sale": " Sale",
    "shopAll": "Shop All {name}",
    "brands": "Brands",
    "shopAllBrands": "Shop All Brands",
    "newIn": "New"
  },
  "ar": {
    "sale": "العروض",
    "shopAll": "شاهد جميع {name}",
    "brands": "الماركات",
    "shopAllBrands": "تسوق جميع الماركات",
    "newIn": "جديد"
  }
}
</i18n>
