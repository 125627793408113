<template>
  <div
    id="app"
    :class="{
      'header-hidden': !showHeader,
      'header-moving': currentScrollPosition > 0,
    }"
  >
    <ClientOnly>
      <BottomSheet />
    </ClientOnly>

    <TheHeader />
    <div id="after-header" />
    <div id="after-header-fade" transition="TransitionFade" />
    <div id="after-header-slide" transition="TransitionSlideUp" />

    <!-- <SideMenu /> -->
    <ClientOnly>
      <CategoryDialog />
    </ClientOnly>

    <ClientOnly>
      <AccountDialog />
    </ClientOnly>

    <main id="main" class="z-auto relative">
      <slot />
    </main>

    <!-- <AsideCart /> -->
    <AlertGroup />

    <ClientOnly>
      <LoginModal />
    </ClientOnly>

    <!-- <ComparisonMenu
      :class="{
        hidden: !shouldShowComparison.value,
      }"
    /> -->
    <ComposeCarouselViewer class="mb-8" />
    <TheFooter class="mb-10" />
    <AppStickyBar class="fixed md:hidden bottom-0 z-30" />
    <VitePwaManifest />
  </div>
</template>

<script setup lang="ts">
import type { Organization, SearchAction, WebSite } from 'schema-dts';
import { throttle } from 'lodash-es';
useSeoLinks();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { shouldShowComparison } = useComparison();
const {
  public: { appUrl: url },
} = useRuntimeConfig();
const showHeader = ref(true);
const currentScrollPosition = ref(0);
const lastScrollPosition = ref(0);
const scrollOffset = ref(100);

const onScroll = throttle(() => {
  currentScrollPosition.value = window.pageYOffset;

  if (window.pageYOffset < 0) {
    return;
  }
  // if the header's distance covered still hasn't exceeded the scrolloffset -> return
  if (Math.abs(window.pageYOffset - lastScrollPosition.value) < scrollOffset.value) {
    return;
  }

  showHeader.value = window.pageYOffset < lastScrollPosition.value;
  lastScrollPosition.value = window.pageYOffset;
}, 300);

watchEffect(() => {
  if (process.server) {
    return;
  }
  // set current scroll position in case page is loaded with scroll
  currentScrollPosition.value = window.pageYOffset;
  window.addEventListener('scroll', onScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll);
});

// https://developers.google.com/search/docs/data-types/sitelinks-searchbox
const websiteWithSearchAction: WebSite = {
  '@type': 'WebSite',
  url,
  potentialAction: {
    '@type': 'SearchAction',
    target: `${url}/search?q={search_term_string}`,
    'query-input': 'required name=search_term_string',
  } as SearchAction,
};

const org: Organization = {
  '@id': url,
  '@type': 'Organization',
  // TODO: Organization name
  name: 'Unique',
  url,
  logo: `${url}/img/logo.png`,
  sameAs: [
    // TODO: Any social links to facebook/twitter/whatever
  ],
};

useJsonld({
  '@context': 'https://schema.org',
  '@graph': [websiteWithSearchAction, org],
});
//** Setting Theme color meta header */
useHead({
  meta: [
    {
      name: 'theme-color',
      content: '#fefdfd',
    },
  ],
});
</script>

<style lang="postcss" scoped>
#app {
  @apply h-full w-full grid;
  grid-template-areas:
    'header'
    'content'
    'footer';

  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  padding-top: 159px;

  @screen md {
    padding-top: 172px;
  }
  @screen lg {
    padding-top: 172px;
  }
}

#main {
  grid-area: content;
}

:deep(.main-navigation) {
  transform: translateY(0);
  transition:
    transform 300ms linear,
    top ease-out 0.2s;
}

.header-moving {
  :deep(.page-header) {
    @apply bg-page-bg;
  }
}
.header-hidden {
  :deep(.main-navigation) {
    transform: translateY(-100%);
  }
}
</style>

<i18n>
{
  "en": {
    "cancel": "Cancel"
  },
  "ar": {
    "cancel": "الغاء"
  }
}
</i18n>
