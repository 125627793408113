<template>
  <header data-attr="header" class="fixed top-0 inset-x-0 main-navigation z-30 bg-page-bg text-black">
    <div class="relative rounded-b-2xs">
      <AppHeader />

      <div class="[ flex items-center flex-wrap md:grid md:grid-cols-1 ]">
        <div class="container mx-auto w-full [ header-title ] border-b border-primary-1-10">
          <div class="border-r border-primary-1-10">
            <QuickSearch class="hidden md:flex z-20" />
          </div>
          <div class="header-mobile relative w-full">
            <AppLink to="/" data-attr="header__home__link" aria-label="application logo" class="mx-auto">
              <KaracaLogo />
            </AppLink>
            <div class="md:hidden w-full">
              <SearchDialog class="header-search w-full border-l h-16 border-primary-1-10" />
            </div>
          </div>

          <div class="flex items-center md:space-x-6">
            <AppLink
              data-attr="header__logIn__link"
              :to="!(isLoggedIn && user?.email) ? '/login' : '/account'"
              class="ml-auto hidden order-1 lg:order-2 md:flex items-center"
              :aria-label="!(isLoggedIn && user?.email) ? $t('loginOrRegister') : $t('greet', { name: user?.name })"
            >
              <svg-icon :name="isLoggedIn ? 'user-active' : 'user'" class="h-6 w-6" />
            </AppLink>

            <AppLink
              to="/cart"
              data-attr="header__cartIcon__btn"
              class="order-4 ml-10 relative hidden md:flex items-center justify-center cursor-pointer"
              aria-label="cart"
            >
              <ClientOnly>
                <svg-icon :name="count ? 'cart-active' : 'cart'" :width="25" :height="25" />
                <span
                  v-show="count"
                  data-attr="header__cartItemCount"
                  class="CartIconCount rounded-full bg-transparent text-primary-1-100 text-base absolute flex items-center justify-center"
                  >({{ count }})</span
                >
                <ComposeAddToCartAlert />
              </ClientOnly>
            </AppLink>
          </div>
        </div>
        <div class="container mx-auto flex">
          <CategoryMegaMenu class="w-full" />
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const QuickSearch = defineAsyncComponent(() => import('./QuickSearch.vue'));
const SearchDialog = defineAsyncComponent(() => import('./SearchDialog.vue'));
const { isLoggedIn, user } = useAuth();
const { count } = useCartAttributes();
const { t: $t } = useI18n({
  useScope: 'local',
});
</script>

<style lang="postcss" scoped>
.CartIconCount {
  width: 0;
  height: 0;
  top: 20px;
  right: -10px;
}

.nuxt-icon svg {
  width: 27px;
  height: 27px;
}
</style>

<i18n>
{
  "en": {
    "loginOrRegister": "Login or Register",
    "contactUs": "Contact us",
    "switchLang": "عربي",
    "logout":  "Logout",
    "customerSupport": "Customer Support",
    "greet": "Hello, {name}"
  },
  "ar": {
    "contactUs": "اتصل بنا",
    "switchLang": "English",
    "logout":  "تسجيل الخروج",
    "customerSupport": "دعم العملاء",
    "loginOrRegister": "تسجيل الدخول",
    "greet": "مرحبا، {name}"
  }
}
</i18n>

<style lang="postcss" scoped>
@screen md {
  .header-title {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .header-title div {
    grid-area: 2;
  }
}

.header-mobile {
  @apply px-6 lg:px-0 py-5;
  display: grid;
  grid-template-columns: 124px 1fr;
  align-items: center;
}

@screen lg {
  .header-title {
    display: grid;
    grid-template-columns: 290px 1fr 250px;
  }
  .header-mobile {
    grid-template-columns: 1fr;
  }
}

:deep(.nuxt-icon svg) {
  width: 25px;
  height: 25px;
}
</style>
