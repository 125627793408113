<template>
  <div class="sticky-bar w-full text-xs [ p-6 pt-0 ]">
    <div class="text-primary-1-100 [ w-full grid grid-cols-5 justify-between ]">
      <AppLink to="/" class="flex items-center flex-col text-center" aria-label="home">
        <svg-icon
          v-if="
            [/index___en/, /index___ar/].some(route => route.test($route?.name?.toString() || '')) &&
            !categoryDialogOpen &&
            !accountDialogOpen
          "
          name="home-active"
          width="24"
          height="24"
        />
        <svg-icon-home v-else width="24" height="24" />

        <span class="mt-2 px-1.5" :class="{ 'font-normal': categoryDialogOpen || accountDialogOpen }">
          {{ $t('home') }}</span
        >
      </AppLink>

      <button
        class="categories flex items-center flex-col text-center"
        :class="{ active: (/category/.test($route.path) || categoryDialogOpen) && !accountDialogOpen }"
        @click="toggleCategoryMenu(true)"
      >
        <svg-icon-categories-active
          v-if="(/category/.test($route.path) || categoryDialogOpen) && !accountDialogOpen"
          width="24"
          height="24"
        />

        <svg-icon-categories v-else width="24" height="24" />

        <span class="mt-2 px-1.5" :class="{ 'font-normal': accountDialogOpen }"> {{ $t('categories') }}</span>
      </button>

      <AppLink to="/cart" class="Cart flex items-center flex-col text-center" aria-label="navigate to cart">
        <svg-icon
          v-if="/cart/.test($route.path) && !categoryDialogOpen && !accountDialogOpen"
          name="responsive-cart-active"
          width="24"
          height="24"
        />
        <svg-icon v-else name="responsive-cart" width="24" height="24" />
        <span class="mt-2 px-1.5" :class="{ 'font-normal': categoryDialogOpen || accountDialogOpen }">
          {{ $t('cart', { count }) }}</span
        >
      </AppLink>

      <AppLink to="/deals" class="flex items-center flex-col text-center" aria-label="navigate to deals">
        <svg-icon-sale-active-dark
          v-if="
            [/deals___en/, /deals___ar/].some(route => route.test($route?.name?.toString() || '')) &&
            !categoryDialogOpen &&
            !accountDialogOpen
          "
          width="24"
          height="24"
        />

        <svg-icon-sale-dark v-else class="text-primary-1-100" width="24" height="24" />
        <span class="mt-2 px-1.5" :class="{ 'font-normal': categoryDialogOpen || accountDialogOpen }">
          {{ $t('offers') }}</span
        >
      </AppLink>

      <button class="Profile flex items-center flex-col text-center" aria-label="navigate to user" @click="toAccount()">
        <svg-icon
          v-if="(/account/.test($route.path) || accountDialogOpen) && !categoryDialogOpen"
          name="user-active"
          width="24"
          height="24"
        />
        <svg-icon v-else name="user" width="24" height="24" />
        <span class="mt-2 px-1.5 whitespace-nowrap" :class="{ 'font-normal': categoryDialogOpen }">
          {{ user?.email ? $t('profile') : $t('login') }}</span
        >
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
const { user } = useAuth();
const { count } = useCartAttributes();
const { emit } = useEventBus();
const { redirect } = useAppRouter();
// const route = useRoute();

const { t: $t } = useI18n({
  useScope: 'local',
});

/**
 * incase user opened the category or account dialog
 * this will be used to switch between active and inactive icon
 */
const categoryDialogOpen = ref(false);
const accountDialogOpen = ref(false);

/**
 * function will be used to change categoryDialogOpen value, and notify listeners.
 */
function toggleCategoryMenu(value: boolean) {
  emit('TOGGLE_CATEGORY_MENU', value);
  toggleAccountMenu(false);
}

/**
 * function will be used to change accountDialogOpen value, and notify listeners.
 */
function toggleAccountMenu(value: boolean) {
  emit('TOGGLE_ACCOUNT_MENU', value);
}

/**
 * listen to that event and change the categoryDialogOpen according to value
 */
useEvent('TOGGLE_CATEGORY_MENU', (value: boolean) => {
  categoryDialogOpen.value = value;
});

/**
 * listen to that event and change the accountDialogOpen according to value
 */
useEvent('TOGGLE_ACCOUNT_MENU', (value: boolean) => {
  accountDialogOpen.value = value;
});

function toAccount() {
  toggleCategoryMenu(false);

  if (user.value) {
    toggleAccountMenu(true);
    return;
  }

  redirect('/login');
}
</script>
<i18n>
{
  "en": {
    "login": "Profile",
    "profile": "Profile",
    "cart": "Cart ({count})",
    "home": "Home",
    "offers": "Sale",
    "categories": "Categories"
  },
  "ar": {
    "login": "تسجيل الدخول",
    "profile": "الملف الشخصي",
    "cart": "السلة ({count})",
    "home": "الرئيسية",
    "offers": "عروض",
    "categories": "الفئات"
    }
}
</i18n>
<style lang="postcss" scoped>
.nuxt-link-exact-active svg {
  @apply text-primary-1-100;
}

a[aria-label^='navigate to'].router-link-active svg {
  @apply text-primary-1-100 font-semibold;
}

.Profile {
  @apply whitespace-nowrap;
}

.curve-icon {
  transform: translateY(calc(-100% + 2px));
  filter: drop-shadow(-3px -4px 3px rgba(215, 215, 215, 0.3));
  z-index: -1;
}

.AppButton {
  @apply font-normal;
  border: none;
  padding: 0;
}

.sticky-bar {
  box-shadow: 0 -10px 30px rgba(26, 25, 25, 0.05);
  height: 88px;
  background: #fff;

  & a,
  & button {
    @apply pt-4;
  }

  & a[aria-label^='navigate to'].router-link-active,
  & a.router-link-exact-active,
  .active {
    @apply font-semibold;
  }
}

[dir='rtl'] {
  .Profile {
    @apply whitespace-normal;
  }

  a[aria-label^='navigate to'].Cart.nuxt-link-active svg,
  & > a[aria-label^='navigate to'].Cart.nuxt-link-exact-active svg,
  & > a[aria-label^='navigate to'].Cart svg {
    transform: scale(-1, 1);
  }
}

:deep(.nuxt-icon svg) {
  height: 28px;
  width: 28px;
}
</style>
