<template>
  <transition-group name="slide-up">
    <div v-if="isOpen" :key="1" class="z-30 fixed inset-x-0 top-10 bottom-0 dialog-slider">
      <div class="absolute inset-0 w-full h-full bg-black opacity-50 z-10" />

      <div
        class="[ flex flex-col lg:hidden ] [ absolute bottom-[88px] ] z-20 w-full bg-primary-50 px-6 pb-16 rounded-t-4xs"
      >
        <button
          class="text-sm [ flex items-center justify-center ] [ w-8 h-8 ] [ absolute top-5 right-5 ] z-50"
          @click="close"
        >
          <svg-icon-close class="text-primary-1-100 w-6 h-6" />
        </button>

        <div v-if="user" class="relative py-8 h-full overflow-y-auto">
          <header class="w-full [ text-primary-1-100 relative ] [ pb-8 z-0] [ flex flex-col justify-between ]">
            <p class="text-system-gray capitalize text-sm mb-2">
              {{ $t('welcome') }}
            </p>

            <h3 class="text-1.5xl font-bold truncate capitalize">{{ user.firstname }} {{ user.lastname }}</h3>
          </header>

          <ul class="Category__Menu">
            <li class="Category__Menu__Item">
              <button
                :aria-label="`navigato to `"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account')"
              >
                <div class="flex items-center">
                  <svg-icon-account-user alt="category image" class="mr-4 object-contain w-6 h-6" />
                  <span class="font-normal text-sm"> {{ $t('accountOverview') }} </span>
                </div>
                <svg-icon-chveron-right
                  class="float-right text-primary-1-100 stroke-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="6"
                  height="12"
                />
              </button>
            </li>

            <li class="Category__Menu__Item">
              <button
                :aria-label="`navigato to `"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account/wishlist')"
              >
                <div class="flex items-center">
                  <svg-icon-favorite-dimmed alt="category image" class="mr-4 object-contain w-6 h-6" />
                  <span class="font-normal text-sm"> {{ $t('myWishlist') }} </span>
                </div>
                <svg-icon-chveron-right
                  class="float-right text-primary-1-100 stroke-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="6"
                  height="12"
                />
              </button>
            </li>

            <li class="Category__Menu__Item">
              <button
                :aria-label="`navigato to `"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account/orders')"
              >
                <div class="flex items-center">
                  <svg-icon-account-orders alt="category image" class="mr-4 object-contain w-6 h-6" />
                  <span class="font-normal text-sm"> {{ $t('myOrders') }} </span>
                </div>
                <svg-icon-chveron-right
                  class="float-right text-primary-1-100 stroke-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="6"
                  height="12"
                />
              </button>
            </li>

            <li class="Category__Menu__Item">
              <button
                :aria-label="`navigato to address book `"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account/addresses')"
              >
                <div class="flex items-center">
                  <svg-icon-account-address alt="category image" class="mr-4 object-contain w-6 h-6" />
                  <span class="font-normal text-sm"> {{ $t('myAddressBook') }} </span>
                </div>
                <svg-icon-chveron-right
                  class="float-right text-primary-1-100 stroke-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="6"
                  height="12"
                />
              </button>
            </li>

            <li class="Category__Menu__Item">
              <button
                :aria-label="`navigato to `"
                class="flex w-full items-center justify-between"
                @click="navigateTo('/account/edit')"
              >
                <div class="flex items-center">
                  <svg-icon-account-settings alt="category image" class="mr-4 object-contain w-6 h-6" />
                  <span class="font-normal text-sm"> {{ $t('accountSetting') }} </span>
                </div>
                <svg-icon-chveron-right
                  class="float-right text-primary-1-100 stroke-current"
                  :class="{
                    'transform scale-x-reverse': $i18n.locale === 'ar',
                  }"
                  width="6"
                  height="12"
                />
              </button>
            </li>
          </ul>

          <div class="my-5 w-full border-t bg-primary-1-05" />
          <ul class="Category__Menu">
            <li class="Category__Menu__Item">
              <button
                :aria-label="`navigato to `"
                class="flex w-full items-center justify-between"
                @click="
                  () => {
                    close();
                    logout();
                  }
                "
              >
                <div class="flex items-center">
                  <svg-icon name="account-logout" class="mr-4" width="24" height="24" />
                  <span class="font-normal text-system-red text-sm"> {{ $t('logout') }} </span>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script setup lang="ts">
const { user } = useAuth();
const { logout } = useLogout();

const isOpen = ref(false);
const { redirect } = useAppRouter();
const route = useRoute();
const { emit } = useEventBus();
const { t: $t } = useI18n({
  useScope: 'local',
});

useEvent('TOGGLE_ACCOUNT_MENU', (value: boolean) => {
  isOpen.value = value;
});

function close() {
  emit('TOGGLE_ACCOUNT_MENU', false);
}

function navigateTo(url: string) {
  close();
  redirect(url);
}

watchEffect(() => {
  if (process.server) {
    return;
  }

  window.document.body.classList.toggle('overflow-hidden', isOpen.value);
});

onBeforeUnmount(() => window.document.body.classList.toggle('overflow-hidden', false));

watch(
  () => route.name,
  () => {
    close();
  },
);
</script>

<style lang="postcss" scoped>
.Category__Menu > li:not(:last-child) {
  &::after {
    @apply flex flex-col bg-primary-1-10 my-5;
    content: ' ';
    width: 100%;
    height: 1px;
  }
}

.dialog-slider {
  @apply pb-20;
  height: calc(var(--screen-height) - 24px);
}

.wishlist-item__bg {
  background: #f9e9ed;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.orders-item__bg {
  background: #f6f3ee;
  background-repeat: no-repeat;
  background-position: 0 0;
}
</style>

<i18n>
{
  "en": {
    "welcome": "Welcome,",
    "close":"Close",
    "accountOverview": "Account Overview",
    "myWishlist": "My Wishlist",
    "myOrders": "My Orders",
    "myAddressBook": "My Address Book",
    "accountSetting": "Account Settings",
    "logout": "Logout",
    "navigateTo": "navigate to"
  },
  "ar": {
    "welcome": "مرحباً،",
    "close":"اغلاق",
    "accountOverview": "نظرة عامة على الحساب",
    "myWishlist": "قائمة امنياتي",
    "myOrders": "طلباتي",
    "myAddressBook": "دفتر عناويني",
    "accountSetting": "إعدادات الحساب",
    "logout": "تسجيل الخروج",
    "navigateTo": "الانتقال إلى"
  }
}
</i18n>
