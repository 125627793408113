<template>
  <div class="flex flex-col space-y-5">
    <ProductsCarousel
      v-for="({ title, subTitle, items }, index) in carouselInfo"
      id="index"
      :key="index"
      :title="title"
      :products="items"
    >
      <template #sub-title>
        <SectionTitle dir="left" class="text-primary-1-100 text-sm justify-center"> {{ subTitle }} </SectionTitle>
      </template>
    </ProductsCarousel>
  </div>
</template>
<script setup lang="ts">
const route = useRoute();
const { carouselInfo } = useCarousel(route);
</script>
